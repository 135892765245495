// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  isps: [],
  plans: [],
  cities: [],
  plansClient: [],
  motivosClient: [],
  error: null,
};

const myprospects = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_USER':
      return {
        ...state,
        allData: action.data,
        selectedUser: null,
        total: action.totalPages,
        params: action.params,
      };
    case 'GET_DATA':
      return {
        ...state,
        allData: action.data,
        selectedUser: null,
        total: action.totalPages,
        params: action.params,
      };
    case 'GET_DATA_USER':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser };
    case 'ADD_USER':
      return { ...state };
    case 'DELETE_USER':
      return { ...state };
    case 'INITIATE_INSTANCE':
      return { ...state };
    case 'GET_ALL_DATA_ISP_X':
      return { ...state, isps: action.data };
    case 'GET_ALL_DATA_PLAN':
      return { ...state, plans: action.data };
    case 'GET_ALL_DATA_CITY':
      return { ...state, cities: action.data };
    case 'FETCH_PLANS_SUCCESS':
      return { ...state, plansClient: action.payload };
    case 'FETCH_PLANS_ERROR':
      return { ...state, error: action.payload };
      case 'FETCH_MOTIVOS_SUCCESS':
      return { ...state, motivosClient: action.payload };
    case 'FETCH_MOTIVOS_ERROR':
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};
export default myprospects;
