// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedIsp: null,
  plans: [],
}

const isps = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_ISP':
      return { ...state, allData: action.data, selectedIsp: null }
    case 'GET_DATA_ISP':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ISP':
      return { ...state, selectedIsp: action.selectedIsp }
    case 'ADD_ISP':
      return { ...state }
    case 'ADD_ISPPLAN':
      return { ...state }
    case 'DELETE_ISP':
      return { ...state }
      case 'GET_ALL_DATA_PLANS':
        return {...state, plans: action.data};
    default:
      return { ...state }
  }
}
export default isps

